import PropTypes from 'prop-types';
import React from 'react';
import DynamicAsset from '../../DynamicAsset';
import '../styles/main.scss';

/**
 * Still Asset
 * This displays a still asset in certrain dimensions
 */
class StillAsset extends React.Component {
    static defaultProps = {
        height: null,
        width: null,
        asset: {}
    };

    static propTypes = {
        height: PropTypes.number,
        width: PropTypes.number,
        asset: PropTypes.object
    };

    render() {
        const { asset, height, width } = this.props;

        let style = {};
        if (asset.image || asset.type == 'image') {
            style = {
                maxWidth: '100%',
                maxHeight: '500px'
            };
        }

        return (
            <div className="still-asset">
                <DynamicAsset {...asset} width={width ? width : null} height={height ? height : null} style={style} />
            </div>
        );
    }
}

export default StillAsset;
